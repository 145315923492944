import { useState, useRef, useEffect, createContext, useContext } from 'react';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker.jsx';
import ReactHtmlParser from 'react-html-parser';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

// User Info
import useUser from '../../hooks/useUser.js';

// Company Logo
import IntuLogo from '../../assets/logos/logo-dark-full.png';

// Axios
import axios from '../../hooks/axios/useAxios.js';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';

// Locations Map
import LocationsMap from '../maps/LocationsMap.jsx';

// Materail UI
import {
  Grid,
  Stack,
  FormControl,
  Button,
  TextField,
  Autocomplete,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  Link,
  Divider,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { classes } from '../../settings/theme.js';
import { MuiTelInput } from 'mui-tel-input';

// Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import AirlineSeatReclineNormalOutlinedIcon from '@mui/icons-material/AirlineSeatReclineNormalOutlined';
import PrintIcon from '@mui/icons-material/Print';

// Country Dialing Code
import countries from '../../data/CountryData.json';

// Response & Loading Handlers
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { PrivacyContent } from '../privacy/PrivacyContent.jsx';

// Translator
import { useTranslation, Trans } from 'react-i18next';
import MapLocationByAddressProvider from '../../context/providers/maps/MapLocationByAddressProvider.jsx';
import { geoCodeAddress } from '../../routes/mapRoutes.js';

const JoinEventcontext = createContext({});

// LOCATION MAP
const MapLocation = () => {
  // Formik Values
  const { values } = useFormikContext();

  //SetUp Axios
  const axios = useAxiosPrivate();

  // Handle Map Rendering
  const { mapRedered, setMapRendered } = useContext(JoinEventcontext);

  // Translator
  const { t } = useTranslation();

  let [locations, setLocations] = useState([
    {
      name: 'Location Name',
      content: 'Location Content',
      coordinates: { latitude: '', longitude: '' },
    },
  ]);

  // Map Existing Locations
  useEffect(() => {
    let location = {
      name: values.event.name,
      street: values.event.address.line1,
      postcode: values.event.address.postcode,
      state: values.event.address.state,
      country: values.event.address.country.label,
      content: values.event.remarks,
    };

    async function getMapLocations() {
      await mapLocationOnMap(location);
    }
    getMapLocations();

    const setMap = setTimeout(() => {
      setMapRendered(true);
    }, 600);
  }, [values.event.address]);

  async function mapLocationOnMap(param) {
    // Set Updated state to false
    setMapRendered(false);

    // Additional Parameters
    const name = param?.name || '';
    const content = param?.content || '';

    const getLocationData = await geoCodeAddress(param);

    if (getLocationData.statusCode === 200) {
      // Update Map Locations
      const newLocations = [
        {
          name: name,
          content: content,
          coordinates: {
            longitude: getLocationData.location.lng,
            latitude: getLocationData.location.lat,
          },
        },
      ];
      setLocations(newLocations);
      return getLocationData;
    }
  }

  return (
    <>
      {mapRedered ? (
        <LocationsMap initialZoom={15} label={true} locations={locations} />
      ) : (
        <Typography variant="body" color="white" align="center">
          {t('context.events.eventFormContext.loading_locations')}
        </Typography>
      )}
    </>
  );
};

// PPA
const ConsentDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Formik Values
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { ppaConsentDialog, setPPAConsentDialog, user } =
    useContext(JoinEventcontext);
  const { t } = useTranslation('translation', { keyPrefix: 'buttons' });

  function handleConsent(consent) {
    setFieldValue('ppa_accepted', {
      accepted: consent,
      ip: consent ? user?.location?.ip : null,
      date: consent ? new Date() : null,
      unixTimeStamp: consent ? Math.floor(Date.now() / 1000) : null,
      agent: consent ? navigator.userAgent : null,
    });
    setTimeout(() => {
      setFieldTouched('ppa_accepted', true);
    }, 600);

    setPPAConsentDialog(false);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="lg"
      open={ppaConsentDialog}
      aria-labelledby="consent-dialog"
    >
      <DialogContent>
        <PrivacyContent />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleConsent(false)}>
          {t('disagree')}
        </Button>
        <Button onClick={() => handleConsent(true)} autoFocus>
          {t('agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EventConfirmation = () => {
  // Form Context
  const { formStatusText } = useContext(JoinEventcontext);

  // Theme
  const theme = useTheme();

  // Formik Values
  const { values } = useFormikContext();

  const [locationDetails, setLocationDetails] = useState({});

  // Translator
  const { t } = useTranslation();

  function printById(id) {
    const printContents = document.getElementById(id).innerHTML;
    const printWindow = window.open('', '_blank');

    printWindow.document.open();
    printWindow.document.write(`
        <html>
            <head>
                <title>Print</title>
                <style>
                    /* Add any additional styling you need here */
                    body { font-family: Arial, sans-serif; }
                    .print-header { text-align: center; margin-bottom: 20px; }
                </style>
            </head>
            <body>
                <div class="print-header">
                    <img width="100" src="${IntuLogo}" alt="Logo" />
                </div>
                <center>
                ${printContents}
                </center>
            </body>
        </html>
    `);
    printWindow.document.close();

    printWindow.focus();
    printWindow.print();
  }

  useEffect(() => {
    let location = {
      name: values.event.name,
      street: values.event.address.line1,
      postcode: values.event.address.postcode,
      state: values.event.address.state,
      country: values.event.address.country.label,
      content: values.event.remarks,
    };

    setLocationDetails(locationDetails);
  }, [values.event.address]);

  return (
    <Grid container>
      {/* Status Text */}

      <Grid item xs={12} sx={{ marginBottom: '30px' }}>
        <Typography
          color="white"
          variant="body1"
          sx={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}
        >
          {ReactHtmlParser(formStatusText)}
        </Typography>
      </Grid>

      {/* Event Confirmation */}
      <Grid item xs={12} container id="eventConfirmation">
        <Grid item xs={12} sx={{ margin: '20px 0 20px 0' }}>
          <Typography color="white" variant="h5">
            {/* Event Confirmation! */}
            {t('context.events.eventFormContext.confirm.title')}
          </Typography>
          <Divider sx={theme.components.MuiDivider} />
        </Grid>

        {/* Event Details */}
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          {/* Event Name */}
          <Typography
            color="white"
            variant="body1"
            sx={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}
          >
            {values.event.name}
          </Typography>

          {/* First Name  & Last Name */}
          <Typography
            color="white"
            variant="body1"
            sx={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}
          >
            {/* Entry Ticket for {values.firstName} {values.lastName} + 1 Person<br /><br /> */}
            {t('context.events.eventFormContext.confirm.name', {
              firstName: values.firstName,
              lastName: values.lastName,
            })}
          </Typography>
          {/* Event Date */}
          <Typography
            color="white"
            variant="body1"
            sx={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}
          >
            {/* Date: {values.event.date} */}
            {t('context.events.eventFormContext.confirm.date', {
              date: values.event.date,
            })}
          </Typography>
          <Divider sx={theme.components.MuiDivider} />
        </Grid>

        {/* Event Location */}
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <Typography color="white" variant="h5">
            {t('context.events.eventFormContext.confirm.location')}
          </Typography>
          <Typography
            color="white"
            variant="body1"
            sx={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}
          >
            {values.event?.address?.line1}
            <br />
            {values.event?.address?.line2 &&
              `${values.event.address.line2}<br/>`}
            {values.event?.address?.city}, {values?.event?.address?.state}{' '}
            {values?.event?.address?.postcode}
            <br />
            {values?.event?.address?.country?.label}
          </Typography>
          <Divider sx={theme.components.MuiDivider} />
        </Grid>

        {/* Remarks */}
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <Typography
            color="white"
            variant="body1"
            sx={{ fontSize: '1.5rem', lineHeight: '1.6rem' }}
          >
            {values.event.remarks}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="outlined"
          endIcon={<PrintIcon />}
          onClick={(e) => printById('eventConfirmation')}
        >
          {t('buttons.print_confirm')}
        </Button>
      </Grid>
    </Grid>
  );
};

// FORM CONTENT
const EventForm = (props) => {
  // Get Form Type (sales or vendor)
  let formType = props?.formType;

  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // State & Context
  const [formStatus, setFormStatus] = useState(true);
  const [formStatusText, setFormStatusText] = useState('');
  const [mapRedered, setMapRendered] = useState(false);

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    event_updates: {
      phone: {
        accepted: '',
        ip: '',
        date: '',
        unixTimeStamp: '',
        agent: '',
      },
      email: {
        accepted: '',
        ip: '',
        date: '',
        unixTimeStamp: '',
        agent: '',
      },
    },
    origin: '',
    sessionID: '',
    location: { longitude: '', latitude: '' },
  });

  // List of Events
  const [events, setEvents] = useState([]);

  // Verification
  const [otp, setOTP] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpIsSending, setOtpIsSending] = useState(false);
  const [otpIsConforming, setOtpIsConfirming] = useState(false);

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  // PPA Consent
  const [ppaConsentDialog, setPPAConsentDialog] = useState(false);

  // User Information
  const { user } = useUser();

  // Translator
  const { t } = useTranslation();
  const { t: transFields } = useTranslation('fields');

  // Setup Initial Values for Form Validation
  //Initialize Validation
  const initialValues = {
    ...userInfo,
    otp: '',
    event: {
      _id: '',
      type: '',
      name: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postcode: '',
        country: {
          label: '',
          dialCode: '',
          code: '',
        },
        coordinates: {
          latitude: '',
          longitude: '',
        },
      },
      remarks: '',
      date: '',
      capacity: '',
    },
    ppa_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    isVerified: false,
    action: props.buttonAction,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(/^(?=.*[a-z]).{2,50}$/, t('validation.name.first_matches'))
      .required(t('validation.name.first_required')),
    lastName: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(/^(?=.*[a-z]).{2,50}$/, t('validation.name.last_matches'))
      .required(t('validation.name.last_required')),
    email: Yup.string()
      .required(t('validation.email.contact_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
    phone: Yup.object({
      value: Yup.string().required(t('validation.mobile.required')),
      info: Yup.object({
        countryCallingCode: Yup.number(
          t('validation.mobile.country_code.invalid'),
        )
          .required(t('validation.mobile.country_code.required'))
          .typeError(t('validation.mobile.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('validation.mobile.required'))
          .min(8, t('validation.mobile.min'))
          .max(15, t('validation.mobile.max')),
      }),
    }),
    ppa_accepted: Yup.object({
      accepted: Yup.boolean().oneOf([true], t('validation.ppa.accepted')),
      ip: Yup.string().required(t('validation.ppa.ip')),
      date: Yup.string().required(t('validation.ppa.date')),
      unixTimeStamp: Yup.string().required(t('validation.ppa.time_stamp')),
      agent: Yup.string().required(t('validation.agent_required')),
    }),
    event: Yup
      // .array()
      // .min(1, t("validation.event_min")),
      .object()
      .shape({
        _id: Yup.string().required(),
        type: Yup.string().required(),
        name: Yup.string().required(),
        address: Yup.object().shape({
          line1: Yup.string().required(),
          line2: Yup.string(),
          city: Yup.string().required(),
          state: Yup.string().required(),
          postcode: Yup.string().required(),
          country: Yup.object().shape({
            label: Yup.string().required(),
            dialCode: Yup.string().required(),
            code: Yup.string().required(),
          }),
          coordinates: Yup.object().shape({
            latitude: Yup.string().required(),
            longitude: Yup.string().required(),
          }),
        }),
        // remarks: Yup.string().required(),
        date: Yup.string().required(),
        capacity: Yup.string().required(),
      }),
    otp: Yup.string()
      .required(t('validation.otp.required'))
      .min(4, t('validation.otp.short'))
      .max(4, t('validation.otp.long')),
  });

  // Pre-Populate form Info prior to load
  useEffect(() => {
    // Get List of Events
    async function getEventsList() {
      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const url = '/api/events/public/list';
        const payload = { authenticated: false };
        // const request = await axios.post(url, { signal });
        const request = await axios.get(url, JSON.stringify(payload), {
          signal,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // const request = await axios.get(url, { signal });

        const response = request?.data;

        if (request.status === 200) {
          // filter Events
          let allEvents = response.data;
          let currentDate = new Date();
          let filteredEvents = allEvents
            .filter((event) => {
              let eventDate = new Date(event.date);
              let eventStatus = event.status;
              return (
                event.type === formType &&
                eventDate >= currentDate &&
                eventStatus === 'active'
              );
            })
            .sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateA - dateB;
            });
          setEvents(filteredEvents);
        } else {
          handleRegularResponse({
            open: true,
            status: 'warning',
            message: response.message,
          });
        }
        return;
      } catch (err) {
        console.log(err);
      } finally {
        controller.abort(signal);
      }
    }

    async function populateUserInfo() {
      // Get User Country Code
      let userPhone;

      if (user.location.country) {
        for (let i = 0; i < countries.length; i++) {
          const element = countries[i];

          if (element.code === user.location.country) {
            userPhone = {
              value: `+${element.dialCode}`,
              info: {
                countryCallingCode: `+${element.dialCode}`,
                countryCode: element.code,
                nationalNumber: null,
                numberType: null,
                numberValue: null,
                reason: 'change',
              },
            };
          }
        }
      }

      let eventUpdates = {
        phone: {
          accepted: true,
          ip: user?.location?.ip,
          date: new Date(),
          unixTimeStamp: Math.floor(Date.now() / 1000),
          agent: navigator.userAgent,
        },
        email: {
          accepted: true,
          ip: user?.location?.ip,
          date: new Date(),
          unixTimeStamp: Math.floor(Date.now() / 1000),
          agent: navigator.userAgent,
        },
      };

      setUserInfo({
        ...userInfo,
        phone: userPhone,
        event_updates: eventUpdates,
        origin: user.location.ip,
        sessionID: user.sessionID,
        location: {
          longitude: user.location.longitude,
          latitude: user.location.latitude,
        },
      });
    }

    const preLoadForm = async () => {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('form.message.fetch_events'),
      });
      await getEventsList();
      await populateUserInfo();
      setIsLoading({ status: false, type: '', text: '' });
    };
    preLoadForm();
  }, [user.location]);

  // Set Up From refs
  const formRef = useRef(null);

  //handle form submission process
  async function submitForm(values, actions) {
    let first_name = values.firstName;
    let last_name = values.lastName;
    let phone = values.phone;
    let email = values.email;
    let event_id = values.event._id;
    let event_name = values.event.name;
    let origin = values.origin;
    let sessionID = values.sessionID;
    let ppa_accepted = values.ppa_accepted;
    let event_updates = values.event_updates;

    // Guard Clause
    if (
      !first_name ||
      !last_name ||
      !phone ||
      !email ||
      !event_id ||
      !event_name ||
      !origin ||
      !sessionID ||
      !ppa_accepted ||
      !event_updates
    ) {
      return;
    }

    const url = `/api/events/join`;
    const payload = {
      first_name,
      last_name,
      email,
      phone,
      event_name,
      event_id,
      ppa_accepted,
      event_updates,
      origin,
      sessionID,
    };
    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('form.message.add_participant'),
      });
      const request = await axios.post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const response = request?.data;

      if (response) {
        //Handle Response
        if (request.status === 200) {
          //message sent
          handleRegularResponse({
            open: true,
            status: 'success',
            message: t('form.message.sent_message'),
          });
          gaEventTracker({
            category: 'General',
            action: 'events',
            label: 'New Participant added!',
            value: 10,
          });
          setFormStatus(false);
          setFormStatusText(response.message);
          // actions.resetForm();
        } else {
          // all other responses
          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        }
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  return (
    <JoinEventcontext.Provider
      value={{
        mapRedered,
        setMapRendered,
        ppaConsentDialog,
        setPPAConsentDialog,
        user,
        formStatusText,
      }}
    >
      <Grid sx={{ padding: '2rem 0rem 2rem 0rem' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => submitForm(values, actions)}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {(formik) => {
            const {
              values,
              setValues,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              isSubmitting,
            } = formik;
            return (
              <Grid sx={{ padding: '2rem 0rem 2rem 0rem' }}>
                <>
                  {formStatus === true ? (
                    <Grid item xs={12}>
                      <Form
                        className="form-horizontal"
                        role="form"
                        onSubmit={handleSubmit}
                      >
                        <FormControl fullWidth>
                          <Stack spacing={4}>
                            {/* Location */}
                            <Typography variant="h4" color="white">
                              {t(
                                'context.events.eventFormContext.choose_location',
                              )}
                            </Typography>
                            <Autocomplete
                              autoComplete={false}
                              required
                              includeInputInList
                              disableClearable={false}
                              id="event"
                              name="event"
                              className="form-select-field"
                              options={events}
                              value={values.event}
                              onChange={(e, options, reason, element) => {
                                setFieldValue('event', options);
                              }}
                              getOptionLabel={(option) =>
                                option?.name ? option?.name : ''
                              }
                              noOptionsText={t('form.event_no_option')}
                              isOptionEqualToValue={(option, value) =>
                                option?._id === value?._id
                              }
                              renderOption={(props, option, { index }) => {
                                const optionDate = new Date(option?.date);
                                return (
                                  <li {...props} key={index}>
                                    <Grid container alignItems="center">
                                      <Grid
                                        item
                                        sx={{ display: 'flex', width: 44 }}
                                      >
                                        <LocationOnIcon
                                          sx={{ color: 'text.secondary' }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        sx={{
                                          width: 'calc(100% - 44px)',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        <Box key={option.id} component="span">
                                          {option?.address?.city} -{' '}
                                          {option?.name} -{' '}
                                          {optionDate.getMonth() + 1}/
                                          {optionDate.getDate()}/
                                          {optionDate.getFullYear()} {`at `}
                                          {optionDate.toLocaleString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                            timeZone: 'UTC',
                                          })}
                                          {` `}
                                          {option?.timezone}
                                          {` Time`}
                                        </Box>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          {option?.address?.line1}{' '}
                                          {option?.address?.line2},{' '}
                                          {option?.address?.city},{' '}
                                          {option?.address?.state}{' '}
                                          {option?.address?.postcode},{' '}
                                          {option?.address?.country?.label}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoComplete="off"
                                  variant="outlined"
                                  label={t('form.event.location_label')}
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'text',
                                    style: { color: 'white' },
                                  }}
                                  InputLabelProps={{
                                    style: { color: 'white' },
                                  }}
                                  value={values?.event}
                                  error={
                                    touched?.event && errors?.event
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    touched?.event && errors?.event
                                      ? errors.event
                                      : null
                                  }
                                />
                              )}
                            />
                            {values?.event?.address?.coordinates?.longitude &&
                              values?.event?.address?.coordinates?.latitude && (
                                <MapLocationByAddressProvider>
                                  <MapLocation />
                                </MapLocationByAddressProvider>
                              )}

                            {mapRedered && (
                              <>
                                {/* Event Remarks */}
                                {values?.event?.remarks && (
                                  <Typography variant="body" color="white">
                                    {values?.event?.remarks}
                                  </Typography>
                                )}

                                {/* Event Availability */}
                                <Typography variant="h3" color="white">
                                  {values?.event?.availability /
                                    values?.event?.capacity >
                                    0.5
                                    ? t(
                                      'context.events.eventFormContext.available_seats',
                                      {
                                        availability:
                                          values?.event?.availability,
                                      },
                                    )
                                    : t(
                                      'context.events.eventFormContext.available_seats_spots',
                                      {
                                        availability:
                                          values?.event?.availability,
                                        capacity: values?.event?.capacity,
                                      },
                                    )}
                                </Typography>

                                {/* First & Last Name */}
                                <Stack
                                  spacing={1}
                                  direction={{ xs: 'column', sm: 'row' }}
                                  style={classes.root}
                                >
                                  <TextField
                                    required
                                    style={classes.root}
                                    id="firstName"
                                    name="firstName"
                                    autoComplete="off"
                                    className="form-select-field"
                                    aria-invalid={
                                      errors.firstName ? 'true' : 'false'
                                    }
                                    aria-describedby="uidnote"
                                    variant="outlined"
                                    label={t('form.first_name')}
                                    type="text"
                                    placeholder={t('form.first_name')}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    inputProps={{
                                      autoComplete: 'given-name',
                                      style: {
                                        color: 'white',
                                        textTransform: 'capitalize',
                                      }, // Set the text color here
                                    }}
                                    InputProps={{ style: { color: 'white' } }}
                                    InputLabelProps={{
                                      style: { color: 'white' },
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.firstName}
                                    error={
                                      errors?.firstName && touched?.firstName
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.firstName && touched?.firstName
                                        ? errors?.firstName
                                        : null
                                    }
                                  />
                                  <TextField
                                    required
                                    id="lastName"
                                    name="lastName"
                                    autoComplete="off"
                                    className="form-select-field"
                                    aria-invalid={
                                      errors.lastName ? 'true' : 'false'
                                    }
                                    aria-describedby="uidnote"
                                    variant="outlined"
                                    label={t('form.last_name')}
                                    type="text"
                                    placeholder={t('form.last_name')}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    inputProps={{
                                      autoComplete: 'family-name',
                                      style: {
                                        color: 'white',
                                        textTransform: 'capitalize',
                                      }, // Set the text color here
                                    }}
                                    InputProps={{ style: { color: 'white' } }}
                                    InputLabelProps={{
                                      style: { color: 'white' },
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.lastName}
                                    error={
                                      errors?.lastName && touched?.lastName
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.lastName && touched?.lastName
                                        ? errors?.lastName
                                        : null
                                    }
                                  />
                                </Stack>

                                {/* Email */}
                                <TextField
                                  required
                                  id="email"
                                  name="email"
                                  autoComplete="off"
                                  className="form-select-field"
                                  aria-invalid={errors.email ? 'true' : 'false'}
                                  aria-describedby="uidnote"
                                  variant="outlined"
                                  label={t('form.email.label_alt')}
                                  type="text"
                                  placeholder={t('form.email.placeholder')}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  inputProps={{
                                    autoComplete: 'email',
                                    style: { color: 'white' },
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.email}
                                  error={
                                    errors?.email && touched?.email
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors?.email && touched?.email
                                      ? errors?.email
                                      : null
                                  }
                                  InputProps={{ style: { color: 'white' } }}
                                  InputLabelProps={{
                                    style: { color: 'white' },
                                  }}
                                />

                                {/* Phone Number */}
                                <MuiTelInput
                                  required
                                  id="phone"
                                  name="phone"
                                  autoComplete="off"
                                  className="form-select-field"
                                  label={transFields('MobileNumberField.label')}
                                  value={values.phone.value}
                                  onChange={(value, info) => {
                                    setValues({
                                      ...values,
                                      phone: { value, info },
                                      isVerifiedt: false,
                                      otp: '',
                                    });

                                    setOtpSent(false);
                                    setOtpVerified(false);
                                  }}
                                  inputProps={{
                                    autoComplete: 'tel-country-code',
                                    style: { color: 'white' },
                                  }}
                                  InputProps={{ style: { color: 'white' } }}
                                  InputLabelProps={{
                                    style: { color: 'white' },
                                  }}
                                  onBlur={handleBlur}
                                />

                                {/* OTP Field */}
                                {otpSent && !otpVerified && (
                                  <TextField
                                    required={otpSent}
                                    id="otp"
                                    name="otp"
                                    className="form-select-field"
                                    autoComplete="off"
                                    aria-invalid={
                                      errors?.otp ? 'true' : 'false'
                                    }
                                    aria-describedby="uidnote"
                                    variant="outlined"
                                    label={t('form.otp.label')}
                                    type="password"
                                    placeholder={t('form.otp.placeholder')}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    inputProps={{
                                      autoComplete: 'one-time-code',
                                      style: { color: 'white' },
                                    }}
                                    InputProps={{ style: { color: 'white' } }}
                                    InputLabelProps={{
                                      style: { color: 'white' },
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.otp}
                                    error={
                                      errors?.otp && touched?.otp ? true : false
                                    }
                                    helperText={
                                      errors?.otp && touched?.otp
                                        ? errors?.otp
                                        : null
                                    }
                                  />
                                )}
                                {/* Request OTP Button */}
                                {!errors?.phone &&
                                  !errors?.firstName &&
                                  !errors?.lastName &&
                                  !errors?.email &&
                                  !otpSent &&
                                  !otpVerified && (
                                    <LoadingButton
                                      variant="outlined"
                                      color="warning"
                                      disabled={
                                        touched?.phone &&
                                          errors?.phone?.info?.countryCallingCode
                                          ? true
                                          : touched?.phone &&
                                            errors?.phone?.info
                                              ?.nationalNumber
                                            ? true
                                            : false
                                      }
                                      onClick={() => {
                                        // handle confirmation
                                        // Guard Clause
                                        if (!values?.phone?.value) {
                                          return window.alert(
                                            t('form.phone.mobile.no_alert'),
                                          );
                                        }
                                        setOtpIsSending(true);

                                        let sendOTP = setTimeout(async () => {
                                          const controller =
                                            new AbortController();
                                          const signal = controller.signal;
                                          let url =
                                            '/api/verify/non-auth/mobile/request';
                                          let payload = {
                                            mobile_number: values?.phone?.value,
                                            full_name: `${values.firstName} ${values.lastName}`,
                                          };

                                          try {
                                            const request = await axios.post(
                                              url,
                                              JSON.stringify(payload),
                                              {
                                                signal,
                                                headers: {
                                                  'Content-Type':
                                                    'application/json',
                                                },
                                                withCredentials: true,
                                              },
                                            );

                                            const response = request?.data;

                                            if (request.status === 200) {
                                              setOtpSent(true);
                                              setOTP(response.otp);
                                              handleRegularResponse({
                                                open: true,
                                                status: response.status,
                                                message: response.message,
                                              });
                                            } else {
                                              handleRegularResponse({
                                                open: true,
                                                status: response.status,
                                                message: response.message,
                                              });
                                            }
                                          } catch (err) {
                                            setOtpSent(false);
                                            handleErrorResponse(err);
                                          } finally {
                                            clearTimeout(sendOTP);
                                            setOtpIsSending(false);
                                          }
                                        }, 100);
                                      }}
                                      sx={{ mr: 1 }}
                                      loading={otpIsSending}
                                      loadingIndicator={
                                        <CircularProgress
                                          size={24}
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.warning.main,
                                          }}
                                        />
                                      }
                                      startIcon={
                                        <SendToMobileOutlinedIcon
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.warning.main,
                                          }}
                                        />
                                      }
                                    >
                                      {t('buttons.confirm_mobile')}
                                    </LoadingButton>
                                  )}

                                {/* Confirm OTP */}
                                {otpSent && !otpVerified && (
                                  <LoadingButton
                                    variant="outlined"
                                    color="success"
                                    disabled={
                                      errors?.otp || !otpSent || values?.otp < 4
                                        ? true
                                        : false
                                    }
                                    loading={otpIsConforming}
                                    loadingIndicator={
                                      <CircularProgress
                                        size={24}
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.success.main,
                                        }}
                                      />
                                    }
                                    startIcon={
                                      <SendToMobileOutlinedIcon
                                        sx={(theme) => ({
                                          color: () =>
                                            errors?.otp ||
                                              !otpSent ||
                                              values?.otp < 4
                                              ? theme.palette.action.disabled // Use the disabled color if the button is disabled
                                              : theme.palette.success.main, // Otherwise use the success color
                                        })}
                                      />
                                    }
                                    onClick={() => {
                                      // handle confirmation
                                      // Guard Clause
                                      if (!values?.otp || !otp) {
                                        return window.alert(
                                          t('form.otp.no_alert'),
                                        );
                                      }
                                      setOtpIsConfirming(true);

                                      let confirmOTP = setTimeout(async () => {
                                        const controller =
                                          new AbortController();
                                        const signal = controller.signal;
                                        let url =
                                          '/api/verify/non-auth/mobile/confirm';
                                        let payload = {
                                          providedOTP: values?.otp,
                                          encryptedOTP: otp,
                                          mobile_number: values?.phone?.value,
                                          full_name: `${values.firstName} ${values.lastName}`,
                                        };
                                        try {
                                          const request = await axios.post(
                                            url,
                                            JSON.stringify(payload),
                                            {
                                              signal,
                                              headers: {
                                                'Content-Type':
                                                  'application/json',
                                              },
                                              withCredentials: true,
                                            },
                                          );

                                          const response = request?.data;

                                          if (request.status === 200) {
                                            setFieldValue('isVerified', true);
                                            setOtpVerified(true);

                                            setTimeout(() => {
                                              setFieldTouched(
                                                'isVerified',
                                                true,
                                                true,
                                              );
                                            }, 1000);
                                          } else {
                                            setFieldValue('otp', '');
                                            setOTP(response.otp);
                                            handleRegularResponse({
                                              open: true,
                                              status: response.status,
                                              message: response.message,
                                            });
                                          }
                                        } catch (err) {
                                          setFieldValue('otp', '');
                                          handleErrorResponse(err);
                                        } finally {
                                          clearTimeout(confirmOTP);
                                          setOtpIsConfirming(false);
                                        }
                                      }, 100);
                                    }}
                                    sx={{ mr: 1 }}
                                  >
                                    {t('buttons.confirm')}
                                  </LoadingButton>
                                )}

                                {/* Consent to updates via mobile Phone  */}
                                {otpSent && otpVerified && (
                                  <FormControlLabel
                                    checked={
                                      values?.event_updates?.phone?.accepted
                                    }
                                    control={
                                      <Checkbox
                                        name="event_updates.phone.accepted"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          let consent = e.target.checked;
                                          setFieldValue('event_updates.phone', {
                                            accepted: consent,
                                            ip: consent
                                              ? user?.location?.ip
                                              : '',
                                            date: consent ? new Date() : '',
                                            unixTimeStamp: consent
                                              ? Math.floor(Date.now() / 1000)
                                              : '',
                                            agent: consent
                                              ? navigator.userAgent
                                              : '',
                                          });
                                          setTimeout(() => {
                                            setFieldTouched(
                                              'vent_updates.phone',
                                              true,
                                            );
                                          }, 300);
                                        }}
                                        inputProps={{
                                          'aria-label': 'controlled',
                                          autoComplete: 'one-time-code',
                                          style: { color: 'white' },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography level="body-sm" color="white">
                                        {t(
                                          'context.events.eventFormContext.mobile_updates',
                                        )}
                                      </Typography>
                                    }
                                  />
                                )}

                                {/* Consent to updates via Email  */}
                                {otpSent && otpVerified && (
                                  <FormControlLabel
                                    checked={
                                      values?.event_updates?.email?.accepted
                                    }
                                    control={
                                      <Checkbox
                                        name="event_updates.email.accepted"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          let consent = e.target.checked;
                                          setFieldValue('event_updates.email', {
                                            accepted: consent,
                                            ip: consent
                                              ? user?.location?.ip
                                              : '',
                                            date: consent ? new Date() : '',
                                            unixTimeStamp: consent
                                              ? Math.floor(Date.now() / 1000)
                                              : '',
                                            agent: consent
                                              ? navigator.userAgent
                                              : '',
                                          });
                                          setTimeout(() => {
                                            setFieldTouched(
                                              'vent_updates.email',
                                              true,
                                            );
                                          }, 300);
                                        }}
                                        inputProps={{
                                          'aria-label': 'controlled',
                                          autoComplete: 'one-time-code',
                                          style: { color: 'white' },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography level="body-sm" color="white">
                                        {t(
                                          'context.events.eventFormContext.email_updates',
                                        )}
                                      </Typography>
                                    }
                                  />
                                )}

                                {/* Consent to Privacy Policy */}
                                {otpSent && otpVerified && (
                                  <>
                                    <FormControlLabel
                                      checked={values.ppa_accepted.accepted}
                                      control={
                                        <Checkbox
                                          name="ppa_accepted"
                                          onChange={(e) => {
                                            let consent = e.target.checked;
                                            setFieldValue('ppa_accepted', {
                                              accepted: consent,
                                              ip: consent
                                                ? user?.location?.ip
                                                : '',
                                              date: consent ? new Date() : '',
                                              unixTimeStamp: consent
                                                ? Math.floor(Date.now() / 1000)
                                                : '',
                                              agent: consent
                                                ? navigator.userAgent
                                                : '',
                                            });
                                            setTimeout(() => {
                                              setFieldTouched(
                                                'ppa_accepted',
                                                true,
                                              );
                                            }, 300);
                                          }}
                                          inputProps={{
                                            'aria-label': 'controlled',
                                          }}
                                        />
                                      }
                                      label={
                                        <Typography
                                          level="body-sm"
                                          color="white"
                                        >
                                          {/* I have read your {<Link className='link' level="body-sm" component="button" onClick={() => setPPAConsentDialog(true)}>privacy policy agreement</Link>} and agree to it. */}
                                          <Trans
                                            i18nKey="form.consent.ppa.accepted"
                                            components={[
                                              <Link
                                                className="link"
                                                level="body-sm"
                                                component="button"
                                                onClick={() =>
                                                  setPPAConsentDialog(true)
                                                }
                                              />,
                                            ]}
                                          />
                                        </Typography>
                                      }
                                    />
                                    <FormHelperText>
                                      {errors?.ppa_accepted?.accepted &&
                                        touched?.ppa_accepted?.accepted ? (
                                        <Typography
                                          variant="body1"
                                          color="error"
                                        >
                                          {t('validation.ppa.accepted_alt')}
                                        </Typography>
                                      ) : null}
                                    </FormHelperText>
                                  </>
                                )}
                                <ConsentDialog formik={formik} />

                                {/* RSVP Button */}
                                {isValid && values.isVerified && (
                                  <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    disabled={!isValid}
                                    loading={isSubmitting}
                                    loadingIndicator={
                                      <CircularProgress
                                        size={24}
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.success.contrastText,
                                        }}
                                      />
                                    }
                                    startIcon={
                                      <AirlineSeatReclineNormalOutlinedIcon
                                        sx={(theme) => ({
                                          color: () =>
                                            errors?.otp ||
                                              !otpSent ||
                                              values?.otp < 4
                                              ? theme.palette.action.disabled // Use the disabled color if the button is disabled
                                              : theme.palette.success
                                                .contrastText, // Otherwise use the success color
                                        })}
                                      />
                                    }
                                  >
                                    {t('buttons.rsvp')}
                                  </LoadingButton>
                                )}
                              </>
                            )}
                          </Stack>
                        </FormControl>
                      </Form>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{ minHeight: '500px' }}
                      alignContent="center"
                      textAlign="center"
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <EventConfirmation />
                      </Grid>
                    </Grid>
                  )}
                </>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    </JoinEventcontext.Provider>
  );
};

export { EventForm };
