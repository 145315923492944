import {
    Box,
    Button,
    Typography,
    Grid,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HowItWorksSignUpForm from '../../components/howitworks/HowItWorksSignUpForm.jsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntuTheme } from '../providers/IntuThemeProvider.jsx';
import { RiveAnimationComponent } from '../../components/animations/RiveAnimationComponent.jsx';
import ResponseContext from '../../context/providers/ResponseProvider.jsx';
import { StatusContent } from '../../components/status/StatusContent.jsx';
import { Video } from '../../plugins/BackgroundVideoPlayer.jsx';

const CreditsExplainerContent = () => {
    const { statusContext } = useContext(ResponseContext);
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix: 'credits' });
    const { t: transButtons } = useTranslation('buttons');
    const { t: transHowItWorks } = useTranslation('translation', {
        keyPrefix: 'pages.home.SalesHome',
    });
    const { mode, mobileView } = useIntuTheme();
    const [isExpanded, setIsExpanded] = useState('');

    const handleScrollTo = (value) => {
        const yOffset = -100;
        let scrollTo = document.getElementById(value).offsetTop + yOffset;
        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    };

    const handleOpen = (panel) => (event, newExpanded) => {
        setIsExpanded(newExpanded ? panel : false);
    };

    return (
        <Grid
            container
            height="100%"
            pb={10}
            justifyContent="space-around"
            flexDirection="row"
        >
            <Grid
                container
                item
                flexDirection={{ xs: 'column', md: 'row' }}
                xs={12}
                md={5}
                justifyContent="space-around"
            >
                {/* Navigation */}
                <Grid item container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label={t('mastering.select.label')}
                            onChange={(e) => {
                                handleScrollTo(e.target.value);
                            }}
                            defaultValue=""
                        >
                            <MenuItem value="mastering">
                                {t('mastering.select.item1')}
                            </MenuItem>
                            <MenuItem value="eligibility">
                                {t('mastering.select.item2')}
                            </MenuItem>
                            <MenuItem value="utilize">{t('mastering.select.item3')}</MenuItem>
                            <MenuItem value="earn">{t('mastering.select.item4')}</MenuItem>
                            <MenuItem value="purchase">
                                {t('mastering.select.item5')}
                            </MenuItem>
                            <MenuItem value="monthly">{t('mastering.select.item6')}</MenuItem>
                            <MenuItem value="unused">{t('mastering.select.item7')}</MenuItem>
                            <MenuItem value="cashout">{t('mastering.select.item8')}</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {/* Explainer Video */}
                <Grid item container pb={12} pt={12} alignItems='flex-start'>
                    <Grid item xs={12}>
                        <Video
                            videoSource={"https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/Links_Video_explained.mp4"}
                            controls
                        />
                    </Grid>
                </Grid>
                {/* Mfg Rive Animation */}
                <Grid item container alignItems='flex-start'>
                    <Grid item xs={12}>
                        <RiveAnimationComponent
                            key={mode}
                            src={
                                mode === 'dark'
                                    ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/links_mfg_(dark_mode).riv'
                                    : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/links_mfg_(light_mode).riv'
                            }
                            stateMachines="Timeline 1"
                            width={mobileView ? '350px' : '600px'}
                            height={mobileView ? '350px' : '600px'}
                        />
                    </Grid>
                </Grid>
                {/* Sales Rive Animation */}
                <Grid item container alignItems='flex-start'>
                    <Grid item xs={12}>
                        <RiveAnimationComponent
                            key={mode}
                            src={
                                mode === 'dark'
                                    ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/links_sales_rep_(dark_mode).riv'
                                    : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/links_sales_rep_(light_mode).riv'
                            }
                            stateMachines="Timeline 1"
                            width={mobileView ? '350px' : '600px'}
                            height={mobileView ? '350px' : '600px'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item
                flexDirection={{ xs: 'column', md: 'row' }}
                xs={12}
                md={6}
                justifyContent="space-around"
            >
                {/* Mastering */}
                <Grid item container pb={10} id="mastering">
                    <Grid item xs={12} pl={2} pr={2}>
                        <Typography variant="h3">{t('mastering.title')}</Typography>
                        <Typography variant="body1">
                            {t('mastering.description')}
                        </Typography>
                    </Grid>
                </Grid>
                {/* Eligibility */}
                <Grid item container pb={10} id="eligibility">
                    <Grid item xs={12}>
                        <Accordion
                            disableGutters
                            slotProps={{
                                transition: {
                                    unmountOnExit: true,
                                },
                            }}
                            expanded={isExpanded === 'eligibility'}
                            onChange={handleOpen('eligibility')}
                            sx={{
                                backgroundImage: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            stroke: mode === 'dark' ? 'gray' : 'black',
                                            strokeWidth: 2,
                                        }}
                                    />
                                }
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid container flexDirection="column">
                                    <Typography variant="h3">{t('eligibility.title')}</Typography>
                                    <Typography variant="body1">
                                        {t('eligibility.description')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1">
                                    {t('eligibility.list.description')}
                                </Typography>
                                <Grid pl={5}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('eligibility.list.item1')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('eligibility.list.item2')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('eligibility.list.item3')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('eligibility.list.item4')} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Utilize LINKS */}
                <Grid item container pb={10} id="utilize">
                    <Grid item xs={12}>
                        <Accordion
                            disableGutters
                            slotProps={{
                                transition: {
                                    unmountOnExit: true,
                                },
                            }}
                            expanded={isExpanded === 'utilize'}
                            onChange={handleOpen('utilize')}
                            sx={{
                                backgroundImage: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            stroke: mode === 'dark' ? 'gray' : 'black',
                                            strokeWidth: 2,
                                        }}
                                    />
                                }
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid container flexDirection="column">
                                    <Typography variant="h3">{t('utilize.title')}</Typography>
                                    <Typography variant="body1">
                                        {t('utilize.description')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid pl={5}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <Typography variant="h6">
                                            {t('utilize.list.sales_title')}
                                        </Typography>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('utilize.list.item1_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('utilize.list.item1_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('utilize.list.item2_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('utilize.list.item2_desc')} />
                                        </ListItem>
                                        <Typography variant="h6">
                                            {t('utilize.list.mfg_title')}
                                        </Typography>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('utilize.list.item3_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('utilize.list.item3_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('utilize.list.item4_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('utilize.list.item4_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('utilize.list.item5_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('utilize.list.item5_desc')} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Earn LINKS */}
                <Grid item container pb={10} id="earn">
                    <Grid item xs={12}>
                        <Accordion
                            disableGutters
                            slotProps={{
                                transition: {
                                    unmountOnExit: true,
                                },
                            }}
                            expanded={isExpanded === 'earn'}
                            onChange={handleOpen('earn')}
                            sx={{
                                backgroundImage: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            stroke: mode === 'dark' ? 'gray' : 'black',
                                            strokeWidth: 2,
                                        }}
                                    />
                                }
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid container item flexDirection="column" xs={12}>
                                    <Typography variant="h3">{t('earn.title')}</Typography>
                                    <Typography variant="body1" align="left">
                                        {t('earn.description')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid pl={5}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <Typography variant="h6">
                                            {t('earn.list.sales_title')}
                                        </Typography>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item1_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item1_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item2_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item2_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item3_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item3_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item4_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item4_desc')} />
                                        </ListItem>
                                        <Grid pl={5}>
                                            <List sx={{ listStyleType: 'disc' }}>
                                                <ListItem sx={{ display: 'list-item' }}>
                                                    <ListItemText
                                                        primary={t('earn.list.sublist.item1')}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}>
                                                    <ListItemText
                                                        primary={t('earn.list.sublist.item2')}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}>
                                                    <ListItemText
                                                        primary={t('earn.list.sublist.item3')}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Typography variant="h6">
                                            {t('earn.list.mfg_title')}
                                        </Typography>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item5_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item5_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item6_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item6_desc')} />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('earn.list.item7_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('earn.list.item7_desc')} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Purchase LINKS */}
                <Grid item container pb={10} id="purchase">
                    <Grid item xs={12}>
                        <Accordion
                            disableGutters
                            slotProps={{
                                transition: {
                                    unmountOnExit: true,
                                },
                            }}
                            expanded={isExpanded === 'purchase'}
                            onChange={handleOpen('purchase')}
                            sx={{
                                backgroundImage: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            stroke: mode === 'dark' ? 'gray' : 'black',
                                            strokeWidth: 2,
                                        }}
                                    />
                                }
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid container flexDirection="column">
                                    <Typography variant="h3">{t('purchase.title')}</Typography>
                                    <Typography variant="body1" align="left">
                                        {t('purchase.description1')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid pl={5}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('purchase.list_item')} />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Typography variant="body1">
                                    {t('purchase.description2')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Monthly LINKS */}
                <Grid item container pb={10} id="monthly">
                    <Grid item xs={12}>
                        <Accordion
                            disableGutters
                            slotProps={{
                                transition: {
                                    unmountOnExit: true,
                                },
                            }}
                            expanded={isExpanded === 'monthly'}
                            onChange={handleOpen('monthly')}
                            sx={{
                                backgroundImage: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            stroke: mode === 'dark' ? 'gray' : 'black',
                                            strokeWidth: 2,
                                        }}
                                    />
                                }
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid container flexDirection="column">
                                    <Typography variant="h3">{t('monthly.title')}</Typography>
                                    <Typography variant="body1">
                                        {t('monthly.description')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid pl={5}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('monthly.list.item1_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('monthly.list.item1_desc')} />
                                        </ListItem>
                                    </List>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('monthly.list.item2_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('monthly.list.item2_desc')} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Unused LINKS */}
                <Grid item container pb={10} id="unused">
                    <Grid item xs={12}>
                        <Accordion
                            disableGutters
                            slotProps={{
                                transition: {
                                    unmountOnExit: true,
                                },
                            }}
                            expanded={isExpanded === 'unused'}
                            onChange={handleOpen('unused')}
                            sx={{
                                backgroundImage: 'none',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            stroke: mode === 'dark' ? 'gray' : 'black',
                                            strokeWidth: 2,
                                        }}
                                    />
                                }
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Grid container flexDirection="column">
                                    <Typography variant="h3">{t('unused.title')}</Typography>
                                    <Typography variant="body1">
                                        {t('unused.description')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid pl={5}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('unused.list.item1_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('unused.list.item1_desc')} />
                                        </ListItem>
                                    </List>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <ListItemText primary={t('unused.list.item2_title')} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={t('unused.list.item2_desc')} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Cashout LINKS */}
                <Grid item container pb={20} id="cashout">
                    <Grid item xs={12} pl={2} pr={2}>
                        <Typography variant="h3">{t('cashout.title')}</Typography>
                        <Typography variant="body1">{t('cashout.description')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* How It Works Signup Form */}
            <Grid item container xs={12}>
                <Box
                    sx={{
                        width: { xs: '80%', sm: '80%', md: '60%' },
                        margin: '0 auto',
                        paddingBottom: { xs: '150px', sm: '150px', md: '100px' },
                    }}
                >
                    {statusContext.open === true ? (
                        <StatusContent />
                    ) : (
                        <>
                            <HowItWorksSignUpForm
                                initialStep={1}
                                initialType="manufacturer"
                                title={transHowItWorks('HowItWorks.title')}
                            />
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography my={5}>
                                    {transHowItWorks('HowItWorks.not_convinced')}
                                </Typography>
                                <Button variant="outlined" onClick={() => navigate('/contact')}>
                                    {transButtons('navigation.contact_us')}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export { CreditsExplainerContent };
