import { Grid, Typography, Box, useTheme } from "@mui/material";
import IntuThemeContext from "../../context/providers/IntuThemeProvider";
import { useContext } from "react";

export const ProfileBlurbComponent = ({
    icon,
    value,
    description
}) => {
    const theme = useTheme();
    const { mode } = useContext(IntuThemeContext)

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary[mode],
                height: '128px',
                borderRadius: '25px',
                width: '100%'
            }}>
            <Grid container flexDirection='row' alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
                <Grid item xs={3}>
                    {icon}
                </Grid>

                {value.includes("NaN") ?
                    <Grid container item flexDirection="column" xs={7.5}>
                        <Typography align='center'>Not Enough Data</Typography>

                    </Grid>
                    :
                    <Grid container item flexDirection="column" xs={7.5}>
                        <Typography align='center'>{value}</Typography>
                        <Typography align='center'>{description}</Typography>
                    </Grid>
                }

            </Grid>
        </Box>
    )
}