import { useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import StyledBadge from './common/StyledBadge';
import { useTranslation } from 'react-i18next';

const ChatSenders = ({ senders, handleSenderChange, selectedSender }) => {
  const { t } = useTranslation();
  const [searchChatValue, setSearchChatValue] = useState('');

  return (
    <Box
      flex={0.35}
      borderRadius={2}
      boxShadow={6}
      p={2}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h6">{t('pages.chat.title')}</Typography>
      <TextField
        type="text"
        label="Search for a chat..."
        value={searchChatValue}
        onChange={(e) => setSearchChatValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchChatValue && (
                <IconButton
                  aria-label="clear input"
                  onClick={() => setSearchChatValue('')}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {senders.size
        ? Array.from(senders)
            .filter(([key, value]) =>
              searchChatValue
                ? key.toLowerCase().includes(searchChatValue.toLowerCase())
                : true,
            )
            .map(([key, value], index) => {
              let image = '';
              if (value.profile_picture) {
                image = `data:${value.profile_picture.mimetype};base64,${value.profile_picture.buffer.toString('base64')}`;
              }
              return (
                <Box mt={2} key={key}>
                  <Badge
                    key={key}
                    badgeContent={value.unread_count}
                    color="primary"
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flex={1}
                      onClick={() => {
                        handleSenderChange(key);
                      }}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedSender === key ? 'primary.light' : 'initial',
                      }}
                      borderRadius={2}
                      p={1}
                    >
                      <Box display="flex" alignItems="center">
                        <StyledBadge
                          // TODO: make this actually work
                          isOnline={index === 1}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <Avatar src={image} alt="profile picture">
                            <FaceIcon sx={{ color: 'primary.dark' }} />
                          </Avatar>
                        </StyledBadge>
                        <Box ml={2}>
                          <Typography
                            variant="body1"
                            color={
                              selectedSender === key
                                ? 'primary.contrastText'
                                : 'primary.dark'
                            }
                          >
                            {key}
                          </Typography>
                          <Typography
                            variant="body2"
                            color={
                              selectedSender === key
                                ? 'primary.contrastText'
                                : 'primary.dark'
                            }
                          >
                            {value.most_recent_message.message}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        minWidth={50}
                        color={
                          selectedSender === key
                            ? 'primary.contrastText'
                            : 'primary.dark'
                        }
                      >
                        {value.most_recent_message.created_at
                          ? dayjs(value.most_recent_message.created_at).format(
                              'h:mm A',
                            )
                          : null}
                      </Typography>
                    </Box>
                  </Badge>
                </Box>
              );
            })
        : null}
    </Box>
  );
};

export default ChatSenders;
