import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import Find from '../../components/find/Find';
import { getSalesReps } from '../../routes/usersRoutes';

const useCreateColumns = () => {
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const navigate = useNavigate();
  return [
    {
      field: 'product_name',
      headerName: 'Sales Rep',
      editable: false,
      flex: 1,
      minWidth: 80,
      sortable: false,
      renderCell: (params) => {
        let image;
        if (params.row.profile_picture) {
          image = `data:${params.row.profile_picture.mimetype};base64,${params.row.profile_picture.buffer.toString('base64')}`;
        }
        return (
          <Box display="flex" alignItems="center">
            <Avatar src={image || ''} alt="brand logo">
              <PersonIcon sx={{ color: 'primary.main' }} />
            </Avatar>
            <Box display="flex" flexDirection="column" ml={2}>
              <Typography fontWeight="bold">{params.row.name}</Typography>
              {/* <Typography fontSize="0.8rem">
                {params.row.city}, {params.row.countryCode}
              </Typography> */}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'net_promoter_score',
      headerName: 'Net Promoter Score',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <LinearProgress
            variant="determinate"
            value={params.row.net_promoter_score * 10}
            color="primary"
            sx={{ width: '100%', borderRadius: 5 }}
          />
        );
      },
    },
    {
      field: 'applications',
      headerName: 'Applications',
      editable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.applications.map((type) => type.name_en).join(', '),
    },
    {
      field: 'product_types',
      headerName: 'Product Type',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: (params) =>
        params.row.product_types.map((type) => type.name_en).join(', '),
    },
    {
      field: 'commission',
      headerName: 'Avg. Commission',
      valueGetter: (params) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.row.average_commission),
      flex: 1,
      editable: false,
    },
    {
      field: 'view',
      editable: false,
      flex: 0.5,
      sortable: false,
      renderHeader: () => null,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={transButtons('view', {
              type: transTypes('salesrep'),
            })}
            onClick={() => navigate(`/app/user-profile/${params.row.id}`)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
  ];
};

const FindSalesRepsPage = () => {
  const { t } = useTranslation();
  const [salesReps, setSalesReps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSalesReps();
  }, []);

  const fetchSalesReps = async () => {
    setIsLoading(true);
    const res = await getSalesReps();
    setSalesReps(res);
    setIsLoading(false);
  };

  const [filters, setFilters] = useState([
    {
      id: 'brand',
      label: 'Names',
      type: 'autocomplete',
      value: [],
      options: [],
    },
    {
      id: 'industry',
      label: 'Industries',
      type: 'autocomplete',
      value: [],
      options: [],
    },
    {
      id: 'product_type',
      label: 'Product Types',
      type: 'autocomplete',
      value: [],
      options: [],
    },
    {
      id: 'product_application',
      label: 'Product Applications',
      type: 'autocomplete',
      value: [],
      options: [],
    },
  ]);

  const handleFilterChange = (newValue, reason, details, filterId) => {
    if (reason === 'clear') {
      setFilters((prevState) => {
        return prevState.map((filter) => {
          if (filter.id === filterId) {
            return {
              ...filter,
              value: [],
            };
          }
          return filter;
        });
      });
      return;
    }

    if (reason === 'removeOption') {
      setFilters((prevState) => {
        return prevState.map((filter) => {
          if (filter.id === filterId) {
            return {
              ...filter,
              value: filter.value.filter((x) => x !== details.option),
            };
          }
          return filter;
        });
      });
      return;
    }

    const value = newValue[newValue.length - 1];
    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            value: [...filter.value, value],
          };
        }
        return filter;
      });
    });
  };

  // Setup options from brandsToRepresent
  const salesRepsOptions = useMemo(() => {
    if (!salesReps.length) return new Set();

    const brandOptionsSet = new Set();
    salesReps.forEach((brand) => brandOptionsSet.add(brand.name));
    return brandOptionsSet;
  }, [salesReps]);

  const industryOptions = useMemo(() => {
    return [];
  }, [salesReps]);

  const productTypeOptions = useMemo(() => {
    if (!salesReps.length) return new Map();

    const productTypeOptionsMap = new Map();
    salesReps.forEach((brand) => {
      brand.product_types.forEach((type) => {
        productTypeOptionsMap.set(type._id, type);
      });
    });
    return productTypeOptionsMap;
  }, [salesReps]);

  const productApplicationOptions = useMemo(() => {
    if (!salesReps.length) return new Map();

    const productApplicationOptionsMap = new Map();
    salesReps.forEach((brand) => {
      brand.applications.forEach((type) => {
        productApplicationOptionsMap.set(type._id, type);
      });
    });
    return productApplicationOptionsMap;
  }, [salesReps]);

  useEffect(() => {
    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === 'brand') {
          return {
            ...filter,
            options: [...salesRepsOptions],
          };
        }
        return filter;
      });
    });
  }, [salesRepsOptions]);

  useEffect(() => {}, [industryOptions]);

  useEffect(() => {
    const optionsArray = Array.from(
      productTypeOptions,
      ([key, value]) => value.name_en,
    );

    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === 'product_type') {
          return {
            ...filter,
            options: optionsArray,
          };
        }
        return filter;
      });
    });
  }, [productTypeOptions]);

  useEffect(() => {
    const optionsArray = Array.from(
      productApplicationOptions,
      ([key, value]) => value.name_en,
    );

    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === 'product_application') {
          return {
            ...filter,
            options: optionsArray,
          };
        }
        return filter;
      });
    });
  }, [productApplicationOptions]);

  const columns = useCreateColumns();

  const filteredList = useMemo(() => {
    const hasSearched = filters.some((filter) => filter.value.length > 0);
    if (!hasSearched) return salesReps;

    // Get existing filters
    const productTypeFilter = filters.find(
      (filter) => filter.id === 'product_type',
    );

    const productApplicationFilter = filters.find(
      (filter) => filter.id === 'product_application',
    );

    const brandFilter = filters.find((filter) => filter.id === 'brand');

    // Check if salesReps contains our filters
    return salesReps.filter((brand) => {
      let contains = false;
      if (productTypeFilter.value.length) {
        let equals = false;
        for (const type of productTypeFilter.value) {
          equals = brand.product_types.some((_type) => _type.name_en === type);
          if (equals) {
            break;
          }
        }
        if (equals) {
          contains = true;
        }
      }

      if (productApplicationFilter.value.length) {
        let equals = false;
        for (const application of productApplicationFilter.value) {
          equals = brand.applications.some(
            (_application) => _application.name_en === application,
          );
          if (equals) {
            break;
          }
        }
        if (equals) {
          contains = true;
        }
      }

      if (brandFilter.value.length) {
        const containsBrandFilter = brandFilter.value.some((filterValue) => {
          return filterValue === brand.name;
        });
        if (containsBrandFilter) {
          contains = true;
        }
      }

      return contains ? brand : null;
    });
  }, [filters]);

  return (
    <Box mt={10} p={4}>
      <Typography variant="h2">Find Sales Reps</Typography>
      {isLoading ? (
        <Skeleton width="100%" height={60} />
      ) : (
        <Find
          list={filteredList}
          filters={filters}
          columns={columns}
          onFilterChange={handleFilterChange}
        />
      )}
    </Box>
  );
};

export default FindSalesRepsPage;
