import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { IntuContactList } from '../../components/admin/IntuContactList';
import { IntuCompanyInfoProvider } from '../../context/providers/IntuCompanyInfoProvider';
import IntuPage from '../../components/layout/IntuPage';

function IntuContacts() {
  const { t } = useTranslation();
  return (
    <IntuPage>
      <IntuForm
        form="Contacts"
        formTitle={t('pages.IntuContacts.title')}
        formText={t('pages.IntuContacts.description')}
      >
        <IntuCompanyInfoProvider>
          <IntuContactList />
        </IntuCompanyInfoProvider>
      </IntuForm>
    </IntuPage>
  );
}

export default IntuContacts;
