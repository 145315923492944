import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import FooterComponent from '../footer/Footer';

const NonAuthLayout = () => {
  return (
    <>
      <Container maxWidth="xxl" disableGutters>
        <Outlet />
      </Container>
      <FooterComponent />
      <LiveChatWidget
        license={process.env.REACT_APP_LIVECHAT_ID}
        visibility="minimized"
      />
    </>
  );
};

export default NonAuthLayout;
